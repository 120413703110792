import useBaseUtils from "~/functions/useBaseUtils"
import SimpleCompanyActions from "~/components/valuationModels/SimpleCompanyActions.vue"
import SimpleCompanyMeta from "~/components/valuationModels/SimpleCompanyMeta.vue"


export default {
  __name: 'SimpleModelHeader',
  props: {
  disabledSaveBtn: { type: Boolean, default: false },
},
  emits: ["save"],
  setup(__props, { emit }) {





const { I18nFn } = useBaseUtils()

return { __sfc: true,emit, I18nFn, SimpleCompanyActions, SimpleCompanyMeta }
}

}